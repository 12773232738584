<template>
  <span :title="title" style="width: 16px">
    <svg width="100%" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="16" rx="0.5" stroke="currentColor" />
      <path
        d="M3.05682 13V4.27273H6.00568C6.6875 4.27273 7.24716 4.3892 7.68466 4.62216C8.12216 4.85227 8.44602 5.16903 8.65625 5.57244C8.86648 5.97585 8.97159 6.43466 8.97159 6.94886C8.97159 7.46307 8.86648 7.91903 8.65625 8.31676C8.44602 8.71449 8.12358 9.02699 7.68892 9.25426C7.25426 9.47869 6.69886 9.59091 6.02273 9.59091H3.63636V8.63636H5.98864C6.45455 8.63636 6.82955 8.56818 7.11364 8.43182C7.40057 8.29545 7.60795 8.10227 7.7358 7.85227C7.86648 7.59943 7.93182 7.2983 7.93182 6.94886C7.93182 6.59943 7.86648 6.29403 7.7358 6.03267C7.60511 5.77131 7.39631 5.5696 7.10938 5.42756C6.82244 5.28267 6.44318 5.21023 5.97159 5.21023H4.11364V13H3.05682ZM7.16477 9.07955L9.3125 13H8.08523L5.97159 9.07955H7.16477ZM10.7326 13V4.27273H13.7837C14.3917 4.27273 14.8931 4.37784 15.288 4.58807C15.6829 4.79545 15.9769 5.07528 16.1701 5.42756C16.3633 5.77699 16.4599 6.16477 16.4599 6.59091C16.4599 6.96591 16.3931 7.27557 16.2596 7.51989C16.1289 7.7642 15.9556 7.95739 15.7397 8.09943C15.5266 8.24148 15.2951 8.34659 15.0451 8.41477V8.5C15.3121 8.51705 15.5806 8.6108 15.8505 8.78125C16.1204 8.9517 16.3462 9.19602 16.5281 9.5142C16.7099 9.83239 16.8008 10.2216 16.8008 10.6818C16.8008 11.1193 16.7013 11.5128 16.5025 11.8622C16.3036 12.2116 15.9897 12.4886 15.5607 12.6932C15.1317 12.8977 14.5735 13 13.886 13H10.7326ZM11.7894 12.0625H13.886C14.5763 12.0625 15.0664 11.929 15.3562 11.6619C15.6488 11.392 15.7951 11.0653 15.7951 10.6818C15.7951 10.3864 15.7198 10.1136 15.5692 9.86364C15.4187 9.6108 15.2042 9.40909 14.9258 9.25852C14.6474 9.10511 14.3178 9.02841 13.9371 9.02841H11.7894V12.0625ZM11.7894 8.10795H13.7496C14.0678 8.10795 14.3548 8.04545 14.6104 7.92045C14.869 7.79545 15.0735 7.61932 15.2241 7.39205C15.3775 7.16477 15.4542 6.89773 15.4542 6.59091C15.4542 6.20739 15.3207 5.8821 15.0536 5.61506C14.7866 5.34517 14.3633 5.21023 13.7837 5.21023H11.7894V8.10795Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
  },
};
</script>
