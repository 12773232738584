<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.discountTypes')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3 align-items-center">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <MultiSelect
              v-model="filterDate"
              :options="filterYearsList"
              :placeholder="$t('channelModal.year')"
              label="id"
              track-by="id"
              class="w-300px"
              @input="callFilterDate"
            ></MultiSelect>
            <label class="mb-0 ml-3">{{ $t('table.type') }}: </label>
            <b-form-select v-model="filterDiscountsType" class="w-150px" @input="pageClick(1)">
              <b-form-select-option :value="null">{{ $t('table.all') }}</b-form-select-option>
              <b-form-select-option :value="1">{{ $t('table.regularPl') }}</b-form-select-option>
              <b-form-select-option :value="2">{{ $t('table.specialPl') }}</b-form-select-option>
            </b-form-select>
          </div>
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th>
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.discountTypes') }}
              </th>
              <th>
                <sort-arrows :sort-string="sortString" :column="'percent,'" @click="sortTable('percent,', $event)" />
                %
              </th>
              <th>
                <sort-arrows :sort-string="sortString" :column="'from,'" @click="sortTable('from,', $event)" />
                {{ $t('table.date') }}
              </th>
              <th>
                {{ $t('channelModal.channel') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="discountTypesStatus !== 'success'">
              <td colspan="4"><SpinnerLoader :loading="discountTypesStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <button v-if="canEdit" class="btn-icon" :title="$t('table.edit')" @click="showModalDiscountTypes(row.id)">
                      <b-icon icon="pencil"></b-icon>
                    </button>
                    <button v-if="canDelete" class="btn-icon mr-4" :title="$t('table.delete')" @click="showModalDeleteDiscountTypes(row)">
                      <b-icon icon="trash"></b-icon>
                    </button>
                    <BIconExclamationTriangle
                      v-if="row.type === 2"
                      class="mr-1 mt-1 text-danger"
                      :title="$t('table.specialDiscount') + '. ' + $t('table.appliesInReportsOnly')"
                    />
                    <IconRB v-if="row.type === 3" class="mr-1 text-danger" :title="$t('RBDiscounts.retroBonusDiscounts')" />
                    {{ row.name }}
                  </div>
                </td>
                <td>{{ row.percent }}%</td>
                <td>{{ row.from | convertDate }} &ndash; {{ row.to | convertDate }}</td>
                <td>
                  {{ row.channel_id && row.channel ? row.channel.name : '' }}
                </td>
              </tr>
              <tr v-if="discountTypesStatus === 'success' && !tableData.length">
                <td colspan="4">
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <div class="d-flex justify-content-between">
          <b-button v-if="$checkPermissions('discount_type.create')" variant="primary" @click="showModalDiscountTypes()">
            {{ $t('table.addNewDiscountTypes') }}
          </b-button>
        </div>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT-->
      <b-modal
        id="discount-types-modal"
        ref="discount-types-modal"
        :title="modalType === 'add' ? $t('table.addNewDiscountTypes') : $t('table.editDiscountTypes')"
        :ok-disabled="$v.$invalid"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-dt-name">
            <b-form-input id="input-dt-name" v-model.trim="modal.title" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('table.selectChannel')">
            <MultiSelect
              v-model.trim="modal.channel"
              label="name"
              track-by="id"
              :options="channelsList"
              :placeholder="$t('table.selectChannel')"
              data-automatic="ms-channel"
            ></MultiSelect>
          </b-form-group>

          <b-form-group :label="$t('table.writeDiscountPercent')" label-for="input-percent">
            <b-form-input
              id="input-percent"
              v-model.number="modal.percent"
              type="number"
              step="any"
              :placeholder="$t('table.writeDiscountPercent')"
              required
              min="-100"
              max="100"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('table.writeDateFrom')" label-for="datepicker-buttons-from">
            <datepicker-wrapper id="datepicker-buttons-from" v-model="modal.dateFrom" required @input="updateDateTo(modal.dateFrom)" />
          </b-form-group>
          <b-form-group :label="$t('table.writeDateTo')" label-for="datepicker-buttons-to">
            <datepicker-wrapper id="datepicker-buttons-to" v-model="modal.dateTo" required :max="maxDate" :min="minDate" />
          </b-form-group>
          <b-form-checkbox v-model="modal.type" name="check-button" switch :disabled="modalType !== 'add'">
            <abbr :title="$t('table.appliesInReportsOnly')">{{ $t('table.specialDiscount') }}</abbr>
          </b-form-checkbox>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SortArrows from '../components/SortArrows';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import convertDate from '../filters/convertDate';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BIconExclamationTriangle } from 'bootstrap-vue';
import IconRB from '@/components/icons/IconRB';

export default {
  name: 'DiscountTypes',
  components: {
    PaginateWrapper,
    SpinnerLoader,
    VocabularyTitle,
    MultiSelect,
    SearchDropdown,
    SearchInput,
    SortArrows,
    DatepickerWrapper,
    BIconExclamationTriangle,
    IconRB,
  },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    selected_year: { type: [String, Number], default: undefined },
    discount_type: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      modal: {
        title: '',
        channel: '',
        dateFrom: '',
        dateTo: '',
        percent: 0,
        type: false, // false=>1 - regular, true=>2 - special
      },
      maxDate: '',
      minDate: '',
      filterDate: '',
      filterDateFrom: '',
      filterDateTo: '',
      filterDiscountsType: null,
      filterYearsList: [],
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      sortString: '-from,name,',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      discountTypes: 'getDiscountTypes',
      discountTypesStatus: 'getDiscountTypesStatus',
      modalEditDiscountTypes: 'getModalEditDiscountTypes',
      year: 'getYear',
      channelsList: 'getChannelsList',
    }),

    canEdit: function () {
      return this.$checkPermissions('discount_type.update');
    },
    canDelete: function () {
      return this.$checkPermissions('discount_type.delete');
    },
  },
  validations: {
    modal: {
      title: {
        required,
      },
      dateFrom: {
        required,
      },
      dateTo: {
        required,
      },
      percent: {
        required,
        minValue: minValue(-100),
        maxValue: maxValue(100),
      },
    },
  },
  watch: {
    discountTypes() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.discountTypes') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getDiscountTypesInfo();
  },
  destroyed() {
    this.$store.commit('clearDiscountTypesList');
    this.$store.commit('clearChannelsList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_DISCOUNT_TYPES', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[from]': this.filterDateFrom || null,
        'filter[to]': this.filterDateTo || null,
        'filter[type]': this.filterDiscountsType || undefined,
        include: 'channel',
      });
      this.updateQuery();
    },

    callFilterDate() {
      this.filterPage = 1;
      this.setFilterDate();
      this.paramsData();
    },

    setFilterDate() {
      if (this.filterDate && this.filterDate.id) {
        this.filterDateFrom = this.filterDate.id + '-01-01';
        this.filterDateTo = this.filterDate.id + '-12-31';
      } else {
        this.filterDateFrom = '';
        this.filterDateTo = '';
      }
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.discountTypes) {
        this.tableData = this.discountTypes.data;
        this.paginationData = this.discountTypes.pagination;
        if (this.filterPage > this.discountTypes.pagination.last_page) {
          this.filterPage = this.discountTypes.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getDiscountTypesInfo() {
      await this.$store.dispatch('GET_YEAR', {});
      await this.$store.dispatch('GET_CHANNELS', { per_page: 1000 });
      this.filterYearsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.year];
      await this.restoreFromQuery('discounts');
      this.paramsData();
    },

    updateDateTo(date) {
      const dateFrom = date.slice(0, 4);
      this.minDate = date;
      this.maxDate = dateFrom + '-12-31';
    },

    clearFields() {
      this.modal = {
        title: '',
        channel: '',
        dateFrom: '',
        dateTo: '',
        percent: 0,
        type: false,
      };
      this.maxDate = '';
      this.minDate = '';
    },

    async showModalDiscountTypes(data) {
      this.isModalLoading = true;
      this.clearFields();
      data ? (this.modalType = 'edit') : (this.modalType = 'add');
      this.$refs['discount-types-modal'].show();
      if (data) {
        await this.$store.dispatch('GET_DISCOUNT_TYPES_ID', data);
        this.modal.title = this.modalEditDiscountTypes.name;
        this.modal.dateFrom = this.modalEditDiscountTypes.from;
        this.modal.dateTo = this.modalEditDiscountTypes.to;
        this.modal.percent = this.modalEditDiscountTypes.percent;
        this.modal.type = this.modalEditDiscountTypes.type === 1 ? false : true;
        this.modal.channel = this.channelsList.find((el) => el.id === this.modalEditDiscountTypes.channel_id) || '';
        this.updateDateTo(this.modal.dateFrom);
      }
      this.isModalLoading = false;
    },

    async addEditDiscountTypes() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditDiscountTypes.id,
        name: this.modal.title,
        percent: +this.modal.percent,
        from: this.modal.dateFrom.slice(0, 10),
        to: this.modal.dateTo.slice(0, 10),
        channel_id: this.modal.channel ? this.modal.channel.id : '',
        type: this.modalType === 'add' ? (this.modal.type === false ? 1 : 2) : undefined,
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_DISCOUNT_TYPES' : 'PUT_DISCOUNT_TYPES', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addDiscountTypes') : this.$i18n.t('alert.editDiscountTypes'),
            text: this.modal.title,
          });
          this.$refs['discount-types-modal'].hide();
          this.paramsData();
          this.clearFields();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteDiscountTypes(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteDiscountTypes')} "${data.name}"?`, {
          title: this.$i18n.t('table.deleteDiscountTypes'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_DISCOUNT_TYPES_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteDiscountTypes'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('table.confirmAddDiscountTypes')} "${this.modal.title}"?`
              : `${this.$i18n.t('table.confirmEditDiscountTypes')} "${this.modal.title}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('table.addDiscountTypes') : this.$i18n.t('table.editDiscountTypes'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-dt-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditDiscountTypes();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
